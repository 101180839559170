<template>
  <div class="notif-bar">
    <b-row no-gutters align-v="center">
      <b-col cols="1"></b-col>
      <b-col cols="10">
        <!-- Notif Bar Content -->
        <slot></slot>
      </b-col>
      <b-col cols="1">
        <b-icon
          class="close-icon"
          icon="x"
          v-if="allowClose"
          @click="$emit('close')"
        ></b-icon>
      </b-col>
    </b-row>
  </div>
</template>

<script>
  export default {
    props : {
      allowClose : {
        type    : Boolean,
        default : false,
      },
    },
  }
</script>

<style lang="scss" scoped>
  @import "../../assets/scss/settings";

  .notif-bar {
    display: block;
    position: fixed;
    width: 100%;
    z-index: 2;
    color: #fff;
    background-color: $tooltip-color;
    box-shadow: 0 3px 10px rgba(119, 119, 119, 0.4);
    font-family: 'ArialRoundedMTBold';
    font-size: 0.9em !important;
    text-align: center;

    .close-icon {
      color: #fff;
      &:hover {
        cursor: pointer;
      }
    }
  }
</style>